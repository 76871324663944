import request from '../utils/request'
//获取优惠券列表
export function getCouponsList(data) {
    return request({
        url: '/platform/activity/couponsManager/data',
        method: 'get',
        data
    })
}
//新增优惠券
export function addTicket(data) {
    return request({
        url: '/platform/activity/couponsManager/addDo',
        method: 'post',
        data
    })
}

export function editTicket(data) {
    return request({
        url: '/platform/activity/couponsManager/editDo',
        method: 'post',
        data
    })
}
export function updStatus(data) {
    return request({
        url: '/platform/activity/couponsManager/updStatus',
        method: 'post',
        data
    })
}


//查询优惠券详情
export function ticketDetail(data) {
    return request({
        url: '/platform/activity/couponsManager/dataByType',
        method: 'get',
        data
    })
}

//优惠券领取记录
export function getHistory(data) {
    return request({
        url: '/platform/activity/coupons/data',
        method: 'get',
        data
    })
}
// http://192.168.110.193:8025/?userId=ecd531d705c245219d90d51418d05515 这个是用户优惠券列表
